:root {
    --Block-margin: 2x 0;
    --TemplateField-padding: 1x;
    --TemplateField-width: 100%;
    --TemplateField-borderRadius: 1x;

    --Image-maxWidth: 100%;
    --Image-align: center;

    --Paragraph-align: left;

    --Button-textTranform: initial;
    --Button-align: left;
    --Button-borderRadius: 1x;
}

.templateField {
    display: block;
    margin: var(--Block-margin);

    &Label {
        display: inline;
        font-weight: bold;

        &Required {

            &::after {
                content: "*";
            }
        }
    }

    &Input {
        display: block;
        font-size: .9em;
        border: none;
        padding: var(--TemplateField-padding);
        width: var(--TemplateField-width);
        background: var(--TemplateField-background, var(--WizardEmbed-inputBackground));
        color: var(--TemplateField-color, var(--WizardEmbed-inputColor));
        border-radius: var(--TemplateField-borderRadius);

        &:read-only {
            opacity: .7;
        }
    }

    &Toggle {
        display: block;
        padding: var(--TemplateField-padding);
        cursor: pointer;

        &Container {
            display: block;
            margin: var(--Block-margin);

            & .templateField, & .toggleInput {
                margin: 0 *;
            }

            &Items {
                display: block;

                &Inline {
                    display: flex;
                    flex-flow: row;
                    flex-wrap: wrap;
                }
            }
        }
    }

    &Select {
        display: block;
        font-size: .9em;
        border: none;
        padding: var(--TemplateField-padding);
        width: var(--TemplateField-width);
        background: var(--TemplateField-background, var(--WizardEmbed-inputBackground));
        color: var(--TemplateField-color, var(--WizardEmbed-inputColor));
        border-radius: var(--TemplateField-borderRadius);
    }
}

.toggleInput {
    margin: var(--Block-margin);

    & .templateField {
        display: inline-flex;
        flex-flow: row-reverse;
        align-items: center;
        margin: 0;

        &Label {
            margin: * 1x;
            cursor: pointer;
            user-select: none;

            &::after {
                content: "";
            }
        }
    }
}

.image {
    display: inline-block;
    width: 100%;
    max-width: var(--Image-maxWidth);

    &Container {
        display: block;
        text-align: var(--Image-align);
        margin: var(--Block-margin);
    }
}

.heading {
    & {
        display: block;
        margin: var(--Block-margin);
    }

    &Left {
        text-align: left;
    }

    &Right {
        text-align: right;
    }

    &Center {
        text-align: center;
    }
}

.paragraph {
    display: block;
    text-align: var(--Paragraph-align);
    margin: var(--Block-margin);
}

.button {
    display: inline-block;
    border: none;
    font-size: 1em;
    text-transform: var(--Button-textTranform);
    background: var(--Button-background, var(--WizardEmbed-secondaryBackground));
    color: var(--Button-color, var(--WizardEmbed-secondaryColor));
    padding: 1x 6x;
    line-height: 1;
    border-radius: var(--Button-borderRadius);
    cursor: pointer;
    user-select: none;
    transition: opacity .25s;

    &:hover, &:focus {
        opacity: .75;
    }

    &:active {
        opacity: .9;
    }

    &IsWide {
        width: 100%;
    }

    &Wrapper {
        text-align: var(--Button-align);
        margin: var(--Block-margin);
    }
}
