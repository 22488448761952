.layout {
    display: grid;
}

.slot {
    &Primary {
        grid-area: primary;
    }
}

.full {
    composes: layout;
    grid-template:
        ". primary ." 1fr
        / auto minmax(auto, 80x) auto;

    & .slotPrimary {
        display: block;
        padding: 4x;
    }
}

