:root {
    --WizardEmbed-height: 100%;
    --WizardEmbed-fontSize: 2.2x;

    /* Color palette */
    --WizardEmbed-primaryBackground: #e3e3e3;
    --WizardEmbed-primaryColor: #222;
    --WizardEmbed-secondaryBackground: #737373;
    --WizardEmbed-secondaryColor: #fff;
    --WizardEmbed-inputBackground: #fff;
    --WizardEmbed-inputColor: #222;
}

wizard-embed {
    box-sizing: border-box;
    display: block;
    height: var(--WizardEmbed-height);
    font-size: var(--WizardEmbed-fontSize);
    background: var(--WizardEmbed-primaryBackground);
    line-height: 1.5;

    & *, & *::before, & *::after {
        box-sizing: inherit;
    }
}

.embed {
    display: grid;
    height: 100%;
}

.error {
    place-self: center;
    font-weight: bold;
    font-size: 1.2em;
}
