:root {
    --Loader-size: 10x;
    --Loader-border-size: .7x;
    --Loader-border-gap: 1x;

    --FloatingLoader-borderRadius: 2x;

    --Container-FloatingLoader-height: 11x;
}

.container {
    display: grid;
    background: var(--Container-background, var(--WizardEmbed-primaryBackground));
    color: var(--Container-color, var(--WizardEmbed-primaryColor));
    grid-template-columns: 1fr var(--Container-FloatingLoader-height);
    grid-template-rows: 1fr var(--Container-FloatingLoader-height);

    & a {
        color: var(--Container-color, var(--WizardEmbed-primaryColor));

        &:hover, &:focus {
            opacity: .7;
        }
    }
}

.pageContent {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
}

.floatingLoaderContainer {
    position: sticky;
    overflow: hidden;
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    bottom: 0;
    right: 0;
    pointer-events: none;
}

.fallback {
    display: block;
    padding: 1x;
}

.loader {
    place-self: center;
    display: block;
    position: relative;
    size: var(--Loader-size);
    border-width: var(--Loader-border-size);
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;

    &::before, &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-width: var(--Loader-border-size);
        border-style: solid;
        border-color:
            var(--Loader-color, var(--WizardEmbed-primaryColor))
            var(--Loader-color, var(--WizardEmbed-primaryColor))
            transparent
            transparent;
        border-radius: 50%;
        opacity: .8;
        animation: spin .8s linear infinite;
    }

    &::after {
        margin: var(--Loader-border-gap);
        top: var(--Loader-border-size);
        bottom: var(--Loader-border-size);
        left: var(--Loader-border-size);
        right: var(--Loader-border-size);
        animation-direction: reverse;
        opacity: .6;
    }
}

.floatingLoader {
    --Loader-size: 5x;
    --Loader-border-size: .5x;
    --Loader-border-gap: .5x;
    --Loader-color: var(--FloatingLoader-color, var(--WizardEmbed-secondaryColor));
    position: absolute;
    right: 2x;
    bottom: 2x;
    padding: 1x;
    background: var(--FloatingLoader-background, var(--WizardEmbed-secondaryBackground));
    border-radius: var(--FloatingLoader-borderRadius);
    box-shadow: 0 .5x 1x rgba(0, 0, 0, .5);
    transform: translateY(150%);
    transition: transform .2s;

    &IsVisible {
        transform: translateY(0);
    }
}

@keyframes spin {
    to {
        transform: rotate(-360deg);
    }
}
